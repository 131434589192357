import {
  required,
  helpers,
  maxLength,
  requiredIf
} from 'vuelidate/lib/validators';

export const inventoryDiv = [
  { value: 'HOU', text: 'HOU' },
  { value: 'LA', text: 'LA' },
  { value: 'PHX', text: 'PHX' },
  { value: 'SAV', text: 'SAV' },
  { value: 'SD', text: 'SD' }
];

export const inventoryType = [
  { value: 'IN - PULLOUT', text: 'IN - PULLOUT' },
  { value: 'IN - LINE', text: 'IN - LINE' },
  { value: 'IN - PICKUP', text: 'IN - PICKUP' },
  { value: 'OUT - DROP-DELIVERY', text: 'OUT - DROP-DELIVERY' },
  { value: 'OUT - LINE', text: 'OUT - LINE' },
  { value: 'OUT - RETURN', text: 'OUT - RETURN' },
  { value: 'BARE', text: 'BARE' }
];

export const inventoryModel = {
  division: '',
  driverId: '',
  locationId: '',
  workOrder: '',
  type: ''
};

export class Inventory {
  date = '';
  time = '';

  division = '';
  driverId = '';

  location = '';
  locationName = '';

  orderNumber = '';
  type = '';

  dotNumber = '';
  plateNumber = '';
  state = '';
  company = '';
  color = '';

  truck = '';
  truckPlate = '';
  truckState = '';
  truckCompany = '';
  truckColor = '';
  truckRemarks = '';

  containerColor = '';
  containerRemarks = '';
  containerNumber = '';
  containerSize = '';
  containerType = '';
  shippingLine = '';

  chassisNumber = '';
  chassisPool = '';
  chassisPlate = '';
  chassisState = '';
  chassisBare: boolean;
  chassisColor = '';
  chassisRemarks = '';

  yard = '';
  seal = '';
  category = '';
  inward = '';
}

export const inventoryActionValidation = {
  orderNumber: { required: requiredIf(form => form.orderNumber) },
  division: { required },
  driverId: { required },
  type: { required: requiredIf(form => form.type) },
  truck: { required, maxLength: maxLength(6) },
  dotNumber: { required, maxLength: maxLength(7) },
  truckPlate: { required, maxLength: maxLength(7) },
  truckCompany: { required },
  truckColor: { required },
  inward: { required }
};
