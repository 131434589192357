













































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
DateType;

import SelectionFilter from '@/shared/components/filters/SelectionFilter.vue';
import { DateType } from '@/pages/Accounting/models/filters/date-filter.model';
import DIVFilter from '@/shared/components/filters/DIVFilter.vue';
import DateFilter from '@/shared/components/filters/DateFilter.vue';
import YMSTypeFilter from '@/pages/YMS/components/YMSTypeFilter.vue';
import moment from 'moment';
import { BS_DATEPICKER_FORMAT } from '@/utils/date.util';

import { YmsModule } from '@/store/index';
import { YmsType } from '@/pages/YMS/models/yms.model';

@Component({
  components: { SelectionFilter, DIVFilter, YMSTypeFilter, DateFilter }
})
export default class DamageReportFilters extends Vue {
  @Prop() type: 'inventory' | 'dayInOut';

  @Watch('$route', { immediate: true })
  onRouterChange(val, oldVal) {
    YmsModule.search({
      type: YmsType.DAMAGE
    });
  }

  selectionTypes = {
    list: [
      { value: 'id', text: 'ID' },
      { value: 'driverId', text: 'Driver' },
      { value: 'truck', text: 'Truck' },
      { value: 'plate', text: 'Plate' },
      { value: 'chassisNumber', text: 'Chassis' },
      { value: 'containerNumber', text: 'Container' }
    ],
    autocompleteList: []
  };

  typeFilter = new FilterInput({
    condition: FilterConditions.Include,
    search: null,
    field: 'type'
  });

  searchFilter = new FilterInput({
    condition: FilterConditions.Include,
    search: null,
    field: 'containerNumber'
  });

  DIVFilter = new FilterInput({
    condition: FilterConditions.Equal,
    search: null,
    field: 'division'
  });

  fromFilter = new FilterInput({
    condition: FilterConditions.GreaterOrEqual,
    search: moment()
      .subtract(12, 'days')
      .format(BS_DATEPICKER_FORMAT),
    field: 'createdDate'
  });

  toFilter = new FilterInput({
    condition: FilterConditions.LessThenOrEqual,
    search: moment().format(BS_DATEPICKER_FORMAT),
    field: 'createdDate'
  });

  excelData: any;

  constructor() {
    super();
  }

  search() {
    YmsModule.setFilters(
      TransformFiltersToJson([
        this.typeFilter,
        this.searchFilter,
        this.DIVFilter,
        this.toFilter,
        this.fromFilter
      ])
    );
    YmsModule.search({
      type: YmsType.DAMAGE
    });
  }

  async excel() {
    YmsModule.setFilters(
      TransformFiltersToJson([
        this.typeFilter,
        this.searchFilter,
        this.DIVFilter,
        this.toFilter,
        this.fromFilter
      ])
    );
    await YmsModule.excel(YmsType.DAMAGE);
    if (YmsModule.excelData) {
      window.open(YmsModule.excelData.url);
    }
  }

  add() {
    YmsModule.setYmsType(YmsType.DAMAGE);
    this.$bvModal.show('damage-report-modal');
  }
}
